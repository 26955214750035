export const PREFIX_KEY = "riviera_nuragica_";

// Local Storage KEY
export const LS_TOKEN_KEY = PREFIX_KEY + "token_user";
export const LS_TOKEN_CONSUMER_KEY = PREFIX_KEY + "token_consumer_user";
export const LS_USER_KEY = PREFIX_KEY + "user_json";
export const LS_USER_CONSUMER_KEY = PREFIX_KEY + "user_consumer_json";

export const LS_DEFAULT_LANGUAGE_KEY = PREFIX_KEY + "language";
export const LS_DEFAULT_CURRENCY_KEY = PREFIX_KEY + "currency";

// URLs
export const BASE_URL_API = "https://dev.api.rivieranuragica.com/v1/";
export const URL_API_USER = "users/";
export const URL_RESERVATION = "reservation/";
export const URL_TRANSACTION = "transaction/";
export const URL_FEE_LOGS = "fee-log/";
export const URL_ANALYTICS = "analytics/";


export const URL_API_USER_LOGIN = URL_API_USER + "auth_token/";
export const URL_API_USER_ME = URL_API_USER + "me/";


export const URL_CURRENCIES = BASE_URL_API + "consumer/currencies/";
export const URL_CASH_REPORT_DAY = BASE_URL_API + "users/me/cash_report/";
export const URL_ESTIMATE_RESERVATION = BASE_URL_API + URL_RESERVATION+ "estimate/";


export const STRIPE_PUBLIC_KEY = 'pk_test_51HiuvPGVSB4LlWHch0euAP2OUzYtf137WnkG4rAPGmdJHSYqIOXx6vpYe8vPW1mnLB5NZhl57KQs3vqlHSyFKsnh00u0ry4b9Q';
export const STRIPE_APPLICATION_FEE_PERCENT = 5;
export const VAT_PERCENT = 22;
export const STRIPE_FIXED_TAX = 0.30;

export const TIMEZONE_DEFAULT = 'Europe/Rome';
export const CURRENCY_DEFAULT = 'EUR';
export const COUNTRY_DEFAULT = 'IT';


// ENUMs


// Options


export const LIMIT_RECORDS_API = 20;
