import {StorageHelper} from "./storageHelper";
import i18n from "../i18n";
import {
    COUNTRY_DEFAULT,
    LIMIT_RECORDS_API,
    STRIPE_APPLICATION_FEE_PERCENT,
    STRIPE_FIXED_TAX,
    TIMEZONE_DEFAULT, URL_CURRENCIES,
    VAT_PERCENT
} from "./constants";

import moment from 'moment'
import {CURRENCY_DEFAULT} from "@/common/constants";
import {converter} from "@lemonneko/currency-converter";
import * as Keys from "./keys"


export class Utils {
    static getRedirectPageNameByUser() {
        let user = StorageHelper.getUser()
        console.log(user)
        if(user.role == Keys.USER_ROLE.ACCOUNTING){
            return "transactions"
        }
        else{
            return "reservations"
        }
    }

    static composeUrlWithLimitAndOffset(url, currentPage=1, limit=LIMIT_RECORDS_API) {
        return url + "?limit=" + limit + "&offset=" + (currentPage - 1) * limit;
    }

    static composeUrlWithInfiniteLimit(url) {
        return url + "?limit=99999999";
    }

    static b64EncodeUnicode (str) {
        // first we use encodeURIComponent to get percent-encoded UTF-8,
        // then we convert the percent encodings into raw bytes which
        // can be fed into btoa.
        return btoa(
            encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(
                match,
                p1
            ) {
                return String.fromCharCode("0x" + p1);
            })
        );
    }

    static getNumberPageByCountLimit(count, limit) {
        let pagesNumber = Math.floor(count / limit);
        let pagesNumberRest = count %  limit;

        if (pagesNumberRest !== 0) {
            pagesNumber++
        }

        return pagesNumber;
    }

    static getDayOfWeekISO(isoDate) {
        let dayOfWeek = new Date(isoDate).getDay();
        if (dayOfWeek === 0) {
            return 6
        } else {
            return dayOfWeek - 1
        }
    }

    static getLongDateFromISO(isoDate) {
        let timezone = Utils.getDefaultTimezone();
        return new Date(isoDate).toLocaleDateString(i18n.locale, {
            hour: '2-digit',
            minute: '2-digit',
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            timeZone: timezone})
    }

    static getDateCalendarCell(isoDate) {
        let timezone = Utils.getDefaultTimezone();
        return new Date(isoDate).toLocaleDateString(i18n.locale, {
            month: 'numeric',
            weekday: 'short',
            day:'numeric',
            timeZone: timezone})
    }

    static getAppointmentDateStringFromIso(isoDate) {
        let timezone = Utils.getDefaultTimezone();
        return new Date(isoDate).toLocaleDateString(i18n.locale, {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            timeZone: timezone})
    }

    /**
     * Conversion short from iso ISO8601 into readable string
     */
    static getShortDateStringFromISO(dateISO) {
        let date = new Date(dateISO);
        return Utils.getShortDateStringFromObj(date);
    }

    static getShortDateStringUTCFromISO(dateISO) {
        let date = new Date(dateISO);
        return Utils.getShortDateStringUTCFromObj(date);
    }

    static getBeatifyShortDateStringFromISO(dateISO) {
        let date = new Date(dateISO);

        let dateString = Utils.getAppointmentDateStringFromIso(date);

        let today = moment().startOf('day');
        let yesterday = moment().subtract(1, 'days').startOf('day');
        let tomorrow = moment().add(1, 'days').startOf('day');


        if (moment(date).isSame(today, 'd')) {
            dateString = i18n.t('common.time.today');
        } else if (moment(date).isSame(tomorrow, 'd')) {
            dateString = i18n.t('common.time.tomorrow');
        } else if (moment(date).isSame(yesterday, 'd')) {
            dateString = i18n.t('common.time.yesterday');
        }

        return dateString;
    }

    static getShortDateStringFromObj(obj) {
        let timezone = Utils.getDefaultTimezone();
        return new Date(obj).toLocaleDateString(i18n.locale, {
            day:'2-digit',
            month: '2-digit',
            year:'numeric',
            timeZone: timezone});
    }

    static getShortDateStringUTCFromObj(obj) {
        return new Date(obj).toLocaleDateString(i18n.locale, {
            day:'2-digit',
            month: '2-digit',
            year:'numeric',
            timeZone: 'UTC'});
    }


    /**
     * Conversion from iso ISO8601 into readable string
     */
    static getDateStringFromISO(dateISO) {
        return Utils.getShortDateStringFromISO(dateISO) + " " + Utils.getHourStringFromISO(dateISO);

    }

    static getDateArrayStringFromISO(arrayDate) {
        let newDateArray = [];
        for (let i = 0; i < arrayDate.length; i++) {
            newDateArray.push(Utils.getDateStringFromISO(arrayDate[i]));
        }
        return newDateArray;
    }

    static getHourStringFromISO(dateISO) {
        let format = 'HH:mm';
        let timezone = Utils.getDefaultTimezone();
        return moment(dateISO).tz(timezone).format(format)

    }

    static getHourStringFromISOLocal(dateISO) {
        let format = 'HH:mm';
        return moment(dateISO).local().format(format)

    }

    static getTimeZoneLocal() {
        let offsetHour = moment().local().utcOffset() / 60;
        let gmtOffset = "";
        if (offsetHour > 0) {
            gmtOffset  += "+"
        }
        gmtOffset += offsetHour;
        return moment.tz.guess(true) + " (UTC" + gmtOffset + ")"
    }

    static getStartEndDateFromString(time){
        let start_date = null;
        let end_date = null;

        if(time === "today") {
            start_date = moment().startOf('day').format('DD/MM/YYYY HH:mm');
            end_date = moment().endOf('day').format('DD/MM/YYYY HH:mm');
        } else if(time === "yesterday") {
            start_date = moment().subtract(1, 'day').startOf('day').format('DD/MM/YYYY HH:mm');
            end_date = moment().subtract(1, 'day').endOf('day').format('DD/MM/YYYY HH:mm');
        } else if(time === "this-week") {
            start_date = moment().startOf('isoWeek').format('DD/MM/YYYY HH:mm');
            end_date = moment().endOf('isoWeek').format('DD/MM/YYYY HH:mm');
        } else if(time === "last-week") {
            start_date = moment().subtract(1, 'isoWeek').startOf('isoWeek').format('DD/MM/YYYY HH:mm');
            end_date = moment().subtract(1, 'isoWeek').endOf('isoWeek').format('DD/MM/YYYY HH:mm');
        } else if(time === "this-month") {
            start_date = moment().startOf('month').format('DD/MM/YYYY HH:mm');
            end_date = moment().endOf('month').format('DD/MM/YYYY HH:mm');
        } else if(time === "last-month") {
            start_date = moment().subtract(1, 'month').startOf('month').format('DD/MM/YYYY HH:mm');
            end_date = moment().subtract(1, 'month').endOf('month').format('DD/MM/YYYY HH:mm');
        } else if(time === "this-year") {
            start_date = moment().startOf('year').format('DD/MM/YYYY HH:mm');
            end_date = moment().endOf('year').format('DD/MM/YYYY HH:mm');
        }

        return {
            start_date: start_date,
            end_date: end_date
        }
    }

    static getGrossPriceOnlineWithCreditCard(price) {
        let price_float = parseFloat(price);
        let fee_without_vat = price_float * (STRIPE_APPLICATION_FEE_PERCENT / 100) + STRIPE_FIXED_TAX;
        let fee_with_vat = fee_without_vat + (fee_without_vat * VAT_PERCENT / 100);
        return (Math.floor((price_float + fee_with_vat) * 100) / 100).toFixed(2)
    }

    static getCurrencySymbolFromString(currency) {
        if (currency) {
            switch (currency.toLowerCase()) {
                case "eur":
                    return "€";
                case "usd":
                    return "$";
                case "cad":
                    return "$";
                default:
                    return currency
            }
        } else {
            return currency
        }

    }

    static getDefaultCurrency() {
        return CURRENCY_DEFAULT;
    }

    static getDefaultCountry() {
        return COUNTRY_DEFAULT;
    }

    static getDefaultTimezone() {
        return TIMEZONE_DEFAULT;
    }


    static getDurationTime(totalSeconds) {
        let hours = Math.floor(totalSeconds / 60 / 60);
        let minutes = Math.floor(totalSeconds / 60 - hours * 60);

        let stringTime = Math.floor(totalSeconds - hours * 60 * 60 - minutes * 60);

        if (stringTime < 10) {
            stringTime = "0" + stringTime
        }


        if (minutes > 0) {
            if (minutes < 10) {
                minutes = "0" + minutes
            }

            stringTime = minutes + ":" + stringTime
        }

        if (hours > 0) {
            if (hours < 10) {
                hours = "0" + hours
            }
            stringTime = hours + ":" + stringTime
        }

        return stringTime;
    }

    static logoutConsumer() {
        StorageHelper.setUserConsumer(null);
        StorageHelper.setTokenConsumer(null);
    }

    static async getPriceString(price, price_currency) {
        let defaultCurrency = Utils.getDefaultCurrency();
        if (defaultCurrency !== price_currency) {
            return await converter.convert({
                to: defaultCurrency,
                from: price_currency,
                amount: price,
                url: URL_CURRENCIES
            }).then((result) => {
                return this.getCurrencySymbolFromString(defaultCurrency) + parseFloat(result.result).toFixed(2)
            }).catch((err) => {
                // catch the error
                console.log(err);
                return this.getCurrencySymbolFromString(defaultCurrency) + price
            })
        } else {
            return this.getCurrencySymbolFromString(price_currency) + price
        }
    }

    static async getPrice(price, price_currency) {
        let defaultCurrency = Utils.getDefaultCurrency();
        if (defaultCurrency !== price_currency) {
            return await converter.convert({
                to: defaultCurrency,
                from: price_currency,
                amount: price,
                url: URL_CURRENCIES
            }).then((result) => {
                return parseFloat(result.result).toFixed(2)
            }).catch((err) => {
                // catch the error
                console.log(err);
                return price
            })
        } else {
            return price
        }
    }

    static getTranslationsPhoneField() {
        return ({
            countrySelectorLabel: i18n.t('phone_number_field.country_code_label'),
            countrySelectorError:  i18n.t('phone_number_field.country_code_error'),
            phoneNumberLabel: i18n.t('phone_number_field.phone_number_label'),
            example: i18n.t('phone_number_field.example'),
        })
    }
}


export const moveUpDatetimePopup = () => {
    let elWithClass = document.getElementsByClassName("vdatetime");
    if (elWithClass.length > 0) {
        for (let i = 0; i < elWithClass.length; i++) {
            if (elWithClass[i].childElementCount > 0) {
                let element = elWithClass[i].lastChild;
                if (element && element.style) {
                    element.style.zIndex = "9999";
                    element.style.position = "fixed";
                    document.body.appendChild(element)
                }
            }
        }
    }
};
