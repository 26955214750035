
import * as Keys from "./keys"
import {COUNTRIES} from "./countries";

export class StringsHelper {

    static getProduct(product) {
        switch (product) {
            case Keys.PRODUCTS.ALBUCCIU:
                return "Nuraghe Albucciu";
            case Keys.PRODUCTS.MORU:
                return "Tomba dei Giganti Moru";
            case Keys.PRODUCTS.MALCHITTU:
                return "Tempietto Malchittu";
            case Keys.PRODUCTS.PRIGIONA:
                return "Nuraghe La Prisgiona";
            case Keys.PRODUCTS.CODDU_ECCHJU:
                return "Tomba dei Giganti Coddu Ecchju";
            case Keys.PRODUCTS.LI_MURI:
                return "Necropoli Li Muri";
            case Keys.PRODUCTS.LI_LOLGHI:
                return "Tomba dei Giganti Li Lolghi";
        }
    }

    static getRate(rate) {
        switch (rate) {
            case Keys.RATES.ADULT.value:
                return "Adulto";
            case Keys.RATES.CHILD.value:
                return "Bambino (fino 11 anni)";
            case Keys.RATES.DISABLED.value:
                return "Diversamente abile";
            case Keys.RATES.RESIDENT_ADULT.value:
                return "Residente adulto";
            case Keys.RATES.RESIDENT_CHILD.value:
                return "Residente bambino";
            case Keys.RATES.RESIDENT_STUDENT.value:
                return "Studente residente";
            case Keys.RATES.NOT_RESIDENT_STUDENT.value:
                return "Studente non residente";
            case Keys.RATES.OTHER_FREE.value:
                return "Altra tariffa gratuita";
            case Keys.RATES.PROFESSOR.value:
                return "Docente/Accompagnatore";
        }
    }

    static getOfferta(reservation) {
        if (reservation.count_products == 1) {
            return StringsHelper.getProduct(reservation.reservation_item.product);
        } else {
            return "Combo (" + reservation.count_products + " siti)"
        }
    }
    static getChannel(channel) {
        switch(channel){
            case Keys.RESERVATION_CHANNEL.OFFLINE:
                return "Biglietteria";
            case Keys.RESERVATION_CHANNEL.ONLINE:
                return "Online";
        }
    }
    static getReservationState(state) {
        switch (state) {
            case Keys.RESERVATION_STATES.PENDING:
                return "In attesa";
            case Keys.RESERVATION_STATES.COMPLETED:
                return "Completata";
            case Keys.RESERVATION_STATES.CANCELED:
                return "Annullata";
            case Keys.RESERVATION_STATES.PAYMENT_REQUIRED:
                return "Pagamento Richiesto";
        }
    }

    static getPaymentModeSimple(payment_mode){
        switch (payment_mode) {
            case Keys.PAYMENT_MODES.CASH:
                return "Contanti";
            case Keys.PAYMENT_MODES.POS:
                return "Pos";
            case Keys.PAYMENT_MODES.CREDIT_CARD:
                return "Carta di credito";
            case Keys.PAYMENT_MODES.TRANSFER:
                return "Bonifico";
            case Keys.PAYMENT_MODES.TO_PAY:
                return "Da pagare";
            default:
                return "Nessuno"
        }
    }


    static getTransactionDateSimple(date){
        return "Transazioni" + StringsHelper.getGeneticDateSimple(date)
    }

    static getReservationDateSimple(date) {
        return "Prenotazioni" + StringsHelper.getGeneticDateSimple(date)
    }

    static getGeneticDateSimple(date) {
        switch (date) {
            case Keys.DATES.TODAY:
                return " di oggi";
            case Keys.DATES.YESTERDAY:
                return " di ieri";
            case Keys.DATES.THIS_WEEK:
                return " di questa settimana";
            case Keys.DATES.LAST_WEEK:
                return " della scorsa settimana";
            case Keys.DATES.THIS_MONTH:
                return " di questo mese";
            case Keys.DATES.LAST_MONTH:
                return " dello scorso mese";
            case Keys.DATES.THIS_YEAR:
                return " di quest'anno";
            default:
                return " di oggi"
        }
    }

    static getCreatorSimple(creator){
        
        switch (creator) {
            case Keys.CREATORS.CODDU_ECCHJU:
                return "Biglietteria Coddu Ecchju";
            case Keys.CREATORS.LI_LOLGHI:
                return "Biglietteria Li Lolghi";
            case Keys.CREATORS.MALCHITTU:
                return "Biglietteria Malchittu";
            case Keys.CREATORS.LA_PRISGIONA:
                return "Biglietteria La Prisgiona";
            case Keys.CREATORS.LI_MURI:
                return "Biglietteria Li Muri";
            default:
                return "Tutti gli utenti"
        }
    }

    static getPaymentMode(payment_mode){

        switch (payment_mode) {
            case Keys.PAYMENT_MODES.CASH:
                return "Pagato (" + StringsHelper.getPaymentModeSimple(payment_mode) +")";
            case Keys.PAYMENT_MODES.POS:
                return "Pagato (" + StringsHelper.getPaymentModeSimple(payment_mode) +")";
            case Keys.PAYMENT_MODES.CREDIT_CARD:
                return "Pagato (" + StringsHelper.getPaymentModeSimple(payment_mode) +")";
            case Keys.PAYMENT_MODES.TRANSFER:
                return StringsHelper.getPaymentModeSimple(payment_mode);
            case Keys.PAYMENT_MODES.TO_PAY:
                return StringsHelper.getPaymentModeSimple(payment_mode);
            default:
                return StringsHelper.getPaymentModeSimple(payment_mode);
        }
    }


    static getClientStringNameFromReservation(reservation) {
        if (reservation.customer_first_name && reservation.customer_last_name) {
            return reservation.customer_first_name + " " + reservation.customer_last_name;
        }
        return "NA";
    }

    static getStringCountryFromISO(isoCountry) {
        return COUNTRIES[isoCountry];
    }

    static getStringOrNA(string) {
        if (string) {
            return string;
        }
        return 'NA'
    }
}
