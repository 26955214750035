import Vue from "vue";
import App from "./App.vue";
import "vue-search-select/dist/VueSearchSelect.css";
import titleMixin from "./common/titleMixin";
import routerManager from "@/router/routerManager";
import i18n from "./i18n";
import localeMixin from "./common/localeMixin";

Vue.config.productionTip = false;

const router = () => {
        return routerManager;
};

Vue.mixin(titleMixin);
Vue.mixin(localeMixin);

new Vue({
    router: router(),
    i18n,
    render: h => h(App)
}).$mount("#app");
