export var COUNTRIES = {};
COUNTRIES.IT = "Italia";
COUNTRIES.DE = "Germania";
COUNTRIES.FR = "Francia";
COUNTRIES.GB = "Regno Unito / Gran Bretagna";
COUNTRIES.BE = "Belgio";
COUNTRIES.NL = "Olanda";
COUNTRIES.PT = "Portogallo";
COUNTRIES.CZ = "Repubblica Ceca";
COUNTRIES.ES = "Spagna";
COUNTRIES.CH = "Svizzera";
COUNTRIES.RU = "Russia";
COUNTRIES.CN = "Cina";
COUNTRIES.JP = "Giappone";
COUNTRIES.A = null;
COUNTRIES.AF = "Afghanistan";
COUNTRIES.AL = "Albania";
COUNTRIES.DZ = "Algeria";
COUNTRIES.AD = "Andorra";
COUNTRIES.AO = "Angola";
COUNTRIES.AI = "Anguilla";
COUNTRIES.AQ = "Antartide";
COUNTRIES.AG = "Antigua e Barbuda";
COUNTRIES.AN = "Antille Olandesi";
COUNTRIES.SA = "Arabia Saudita";
COUNTRIES.AR = "Argentina";
COUNTRIES.AM = "Armenia";
COUNTRIES.AW = "Aruba";
COUNTRIES.AU = "Australia";
COUNTRIES.AT = "Austria";
COUNTRIES.AZ = "Azerbaijan";
COUNTRIES.B = null;
COUNTRIES.BS = "Bahamas";
COUNTRIES.BH = "Bahrain";
COUNTRIES.BD = "Bangladesh";
COUNTRIES.BB = "Barbados";
COUNTRIES.BZ = "Belize";
COUNTRIES.BJ = "Benin";
COUNTRIES.BM = "Bermuda";
COUNTRIES.BY = "Bielorussia";
COUNTRIES.BT = "Bhutan";
COUNTRIES.BO = "Bolivia";
COUNTRIES.BA = "Bosnia Erzegovina";
COUNTRIES.BW = "Botswana";
COUNTRIES.BR = "Brasile";
COUNTRIES.BN = "Brunei Darussalam";
COUNTRIES.BG = "Bulgaria";
COUNTRIES.BF = "Burkina Faso";
COUNTRIES.BI = "Burundi";
COUNTRIES.C = null;
COUNTRIES.KH = "Cambogia";
COUNTRIES.CM = "Camerun";
COUNTRIES.CA = "Canada";
COUNTRIES.CV = "Capo Verde";
COUNTRIES.TD = "Ciad";
COUNTRIES.CL = "Cile";
COUNTRIES.CY = "Cipro";
COUNTRIES.VA = "Citta del Vaticano";
COUNTRIES.CO = "Colombia";
COUNTRIES.KM = "Comore";
COUNTRIES.KP = "Corea del Nord";
COUNTRIES.KR = "Corea del Sud";
COUNTRIES.CR = "Costa Rica";
COUNTRIES.CI = "Costa d'Avorio";
COUNTRIES.HR = "Croazia";
COUNTRIES.CU = "Cuba";
COUNTRIES.D = null;
COUNTRIES.DK = "Danimarca";
COUNTRIES.DM = "Dominica";
COUNTRIES.E = null;
COUNTRIES.EC = "Ecuador";
COUNTRIES.EG = "Egitto";
COUNTRIES.IE = "Eire";
COUNTRIES.SV = "El Salvador";
COUNTRIES.AE = "Emirati Arabi Uniti";
COUNTRIES.ER = "Eritrea";
COUNTRIES.EE = "Estonia";
COUNTRIES.ET = "Etiopia";
COUNTRIES.F = null;
COUNTRIES.FJ = "Fiji";
COUNTRIES.PH = "Filippine";
COUNTRIES.FI = "Finlandia";
COUNTRIES.G = null;
COUNTRIES.GA = "Gabon";
COUNTRIES.GM = "Gambia";
COUNTRIES.GE = "Georgia";
COUNTRIES.GH = "Ghana";
COUNTRIES.JM = "Giamaica";
COUNTRIES.GI = "Gibilterra";
COUNTRIES.DJ = "Gibuti";
COUNTRIES.JO = "Giordania";
COUNTRIES.GR = "Grecia";
COUNTRIES.GD = "Grenada";
COUNTRIES.GL = "Groenlandia";
COUNTRIES.GP = "Guadalupa";
COUNTRIES.GU = "Guam";
COUNTRIES.GT = "Guatemala";
COUNTRIES.GG = "Guernsey";
COUNTRIES.GN = "Guinea";
COUNTRIES.GW = "Guinea-Bissau";
COUNTRIES.GQ = "Guinea Equatoriale";
COUNTRIES.GY = "Guyana";
COUNTRIES.GF = "Guyana Francese";
COUNTRIES.H = null;
COUNTRIES.HT = "Haiti";
COUNTRIES.HN = "Honduras";
COUNTRIES.HK = "Hong Kong";
COUNTRIES.I = null;
COUNTRIES.IN = "India";
COUNTRIES.ID = "Indonesia";
COUNTRIES.IR = "Iran";
COUNTRIES.IQ = "Iraq";
COUNTRIES.BV = "Isola di Bouvet";
COUNTRIES.CX = "Isola di Natale";
COUNTRIES.HM = "Isola Heard e Isole McDonald";
COUNTRIES.KY = "Isole Cayman";
COUNTRIES.CC = "Isole Cocos";
COUNTRIES.CK = "Isole Cook";
COUNTRIES.FK = "Isole Falkland";
COUNTRIES.FO = "Isole Faroe";
COUNTRIES.MH = "Isole Marshall";
COUNTRIES.MP = "Isole Marianne Settentrionali";
COUNTRIES.UM = "Isole Minori degli Stati Uniti d'America";
COUNTRIES.NF = "Isola Norfolk";
COUNTRIES.SB = "Isole Solomon";
COUNTRIES.TC = "Isole Turks e Caicos";
COUNTRIES.VI = "Isole Vergini Americane";
COUNTRIES.VG = "Isole Vergini Britanniche";
COUNTRIES.IL = "Israele";
COUNTRIES.IS = "Islanda";
COUNTRIES.K = null;
COUNTRIES.KZ = "Kazakhistan";
COUNTRIES.KE = "Kenya";
COUNTRIES.KG = "Kirghizistan";
COUNTRIES.KI = "Kiribati";
COUNTRIES.XK = "Kosovo";
COUNTRIES.KW = "Kuwait";
COUNTRIES.L = null;
COUNTRIES.LA = "Laos";
COUNTRIES.LV = "Lettonia";
COUNTRIES.LS = "Lesotho";
COUNTRIES.LB = "Libano";
COUNTRIES.LR = "Liberia";
COUNTRIES.LY = "Libia";
COUNTRIES.LI = "Liechtenstein";
COUNTRIES.LT = "Lituania";
COUNTRIES.LU = "Lussemburgo";
COUNTRIES.M = null;
COUNTRIES.MO = "Macao";
COUNTRIES.MK = "Macedonia";
COUNTRIES.MG = "Madagascar";
COUNTRIES.MW = "Malawi";
COUNTRIES.MV = "Maldive";
COUNTRIES.MY = "Malesia";
COUNTRIES.ML = "Mali";
COUNTRIES.MT = "Malta";
COUNTRIES.MA = "Marocco";
COUNTRIES.MQ = "Martinica";
COUNTRIES.MR = "Mauritania";
COUNTRIES.MU = "Maurizius";
COUNTRIES.YT = "Mayotte";
COUNTRIES.MX = "Messico";
COUNTRIES.MD = "Moldavia";
COUNTRIES.MC = "Monaco";
COUNTRIES.MN = "Mongolia";
COUNTRIES.ME = "Montenegro";
COUNTRIES.MS = "Montserrat";
COUNTRIES.MZ = "Mozambico";
COUNTRIES.MM = "Myanmar";
COUNTRIES.N = null;
COUNTRIES.NA = "Namibia";
COUNTRIES.NR = "Nauru";
COUNTRIES.NP = "Nepal";
COUNTRIES.NI = "Nicaragua";
COUNTRIES.NE = "Niger";
COUNTRIES.NG = "Nigeria";
COUNTRIES.NU = "Niue";
COUNTRIES.NO = "Norvegia";
COUNTRIES.NC = "Nuova Caledonia";
COUNTRIES.NZ = "Nuova Zelanda";
COUNTRIES.O = null;
COUNTRIES.OM = "Oman";
COUNTRIES.P = null;
COUNTRIES.PK = "Pakistan";
COUNTRIES.PW = "Palau";
COUNTRIES.PA = "Panama";
COUNTRIES.PG = "Papua Nuova Guinea";
COUNTRIES.PY = "Paraguay";
COUNTRIES.PE = "Peru";
COUNTRIES.PN = "Pitcairn";
COUNTRIES.PF = "Polinesia Francese";
COUNTRIES.PL = "Polonia";
COUNTRIES.PR = "Porto Rico";
COUNTRIES.Q = null;
COUNTRIES.QA = "Qatar";
COUNTRIES.R = null;
COUNTRIES.CF = "Repubblica Centroafricana";
COUNTRIES.CG = "Repubblica del Congo";
COUNTRIES.CD = "Repubblica Democratica del Congo";
COUNTRIES.DO = "Repubblica Dominicana";
COUNTRIES.RE = "Reunion";
COUNTRIES.RO = "Romania";
COUNTRIES.RW = "Ruanda";
COUNTRIES.S = null;
COUNTRIES.EH = "Sahara Occidentale";
COUNTRIES.KN = "Saint Kitts e Nevis";
COUNTRIES.PM = "Saint Pierre e Miquelon";
COUNTRIES.VC = "Saint Vincent e Grenadine";
COUNTRIES.WS = "Samoa";
COUNTRIES.AS = "Samoa Americane";
COUNTRIES.SM = "San Marino";
COUNTRIES.SH = "Sant'Elena";
COUNTRIES.LC = "Santa Lucia";
COUNTRIES.ST = "Sao Tome e Principe";
COUNTRIES.SN = "Senegal";
COUNTRIES.SC = "Seychelles";
COUNTRIES.RS = "Serbia";
COUNTRIES.SL = "Sierra Leone";
COUNTRIES.SG = "Singapore";
COUNTRIES.SY = "Siria";
COUNTRIES.SK = "Slovacchia";
COUNTRIES.SI = "Slovenia";
COUNTRIES.SO = "Somalia";
COUNTRIES.LK = "Sri Lanka";
COUNTRIES.FM = "Stati Federati della Micronesia";
COUNTRIES.US = "Stati Uniti d'America";
COUNTRIES.ZA = "Sud Africa";
COUNTRIES.GS = "Sud Georgia e Isole Sandwich";
COUNTRIES.SD = "Sudan";
COUNTRIES.SR = "Suriname";
COUNTRIES.SJ = "Svalbard e Jan Mayen";
COUNTRIES.SE = "Svezia";
COUNTRIES.SZ = "Swaziland";
COUNTRIES.T = null;
COUNTRIES.TJ = "Tagikistan";
COUNTRIES.TH = "Tailandia";
COUNTRIES.TW = "Taiwan";
COUNTRIES.TZ = "Tanzania";
COUNTRIES.IO = "Territori Britannici dell'Oceano Indiano";
COUNTRIES.TF = "Territori Francesi del Sud";
COUNTRIES.PS = "Territori Palestinesi Occupati";
COUNTRIES.TP = "Timor Est";
COUNTRIES.TG = "Togo";
COUNTRIES.TK = "Tokelau";
COUNTRIES.TO = "Tonga";
COUNTRIES.TT = "Trinidad e Tobago";
COUNTRIES.TN = "Tunisia";
COUNTRIES.TR = "Turchia";
COUNTRIES.TM = "Turkmenistan";
COUNTRIES.TV = "Tuvalu";
COUNTRIES.U = null;
COUNTRIES.UA = "Ucraina";
COUNTRIES.UG = "Uganda";
COUNTRIES.HU = "Ungheria";
COUNTRIES.UY = "Uruguay";
COUNTRIES.UZ = "Uzbekistan";
COUNTRIES.V = null;
COUNTRIES.VU = "Vanuatu";
COUNTRIES.VE = "Venezuela";
COUNTRIES.VN = "Vietnam";
COUNTRIES.W = null;
COUNTRIES.WF = "Wallis e Futuna";
COUNTRIES.Y = null;
COUNTRIES.YE = "Yemen";
COUNTRIES.Z = null;
COUNTRIES.ZM = "Zambia";
COUNTRIES.ZW = "Zimbabwe";
